import React from 'react'
import './style.css'
import ApplePay from '../../assets/ApplePay_2022-05-15/ApplePay.png'
import MasterCard from '../../assets/MasterCard_2022-05-15/MasterCard.png'
import Paypal from '../../assets/PayPal_2022-05-15/PayPal.png'
function Footer() {
  return (
    <div className="desktopfooter">
      <div className="links flex flex-col md:flex-row md:justify-between mt-8 text-white">
        <div className="flex md:flex-col justify-evenly mb-8 items-start text-white">
          <span className=" mb-2">Home</span>
          <span className=" mb-2">About</span>
          <span className=" mb-2"> Contact</span>
          <span className=" mb-2">Events</span>
          <span className=" mb-2"> My Orders</span>
        </div>
        <div className="copy">
          <div>&#169;2010-2022 xxx All Rights Reserved</div>
          <div>
            Privace Center | Privacy & Cookie Policy | Manage Cookies |{' '}
          </div>
          <div className=" mt-4">Terms & Conditions | Copyright Notice |</div>
          <div className=" mt-8">We Accept</div>
          <div className=" flex items-center">
            <img alt="mastercard" className=" mr-2" src={MasterCard} />
            <img alt="paypal" className=" mr-2" src={Paypal} />
            <img alt="applepay" src={ApplePay} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Footer
