import React, { createContext, useState } from 'react'

const GlobalContext = createContext(null)

export const Provider = (props) => {
  const [event, setEvent] = useState(null)
  const [eventname, setEventname] = useState(null)
  return (
    <GlobalContext.Provider
      value={{ event, setEvent, eventname, setEventname }}
    >
      {props.children}
    </GlobalContext.Provider>
  )
}

export default GlobalContext
