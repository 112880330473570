import React, { useEffect, useState } from 'react'
import {
  GetEventGalleryFromId,
  SearchBibData,
} from '../../services/event.service'
import err404 from '../../assets/noimage.jpg'
import { useNavigate } from 'react-router-dom'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

function ProductBox({
  eventId,
  bib,
  eventParam,
  resultingPhotos = undefined,
  handleCloseBib,
}) {
  const [photo, setPhoto] = useState([])
  const navigate = useNavigate()
  const [open, setOpen] = useState(true)
  const [notfound, setNotfound] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (eventId) {
        fetchData()
      } else {
        return
      }
    }, 2000)
    return () => clearTimeout(timeOutId)
    // eslint-disable-next-line
  }, [bib, eventId])

  const fetchData = async () => {
    if (bib) {
      let body = {
        labels: {
          bib: bib,
        },
      }
      try {
        const data = await SearchBibData({
          eventID: eventId,
          pageNumber: 1,
          pageSize: 10,
          body,
        })
        if (data.error) {
          window.alert(data.error.message)
          return
        }
        if (data.finresult.length === 0) {
          setNotfound(!notfound)
        }
        setPhoto(data.finresult)
        handleCloseBib()
      } catch (err) {
        console.log(err)
      }
    } else {
      GetEventGalleryFromId({ eventID: eventId, pageNumber: 1, pageSize: 10 })
        .then((data) => {
          if (data.error) {
            window.alert(data.error.message)
            return
          }
          setPhoto(data.photos)
          handleClose()
        })
        .catch((err) => console.log(err))
    }
  }
  return (
    <div
      style={{
        // height: '150vh',
        width: '100%',
        verticalAlign: 'middle',
        padding: '10px',
        position: 'relative',
      }}
    >
      {resultingPhotos
        ? resultingPhotos
        : photo.map((item) => {
            return (
              <div
                key={item._id}
                style={{
                  display: 'inline-block',
                  padding: '10px',
                  marginBottom: '10px',
                }}
              >
                <img
                  src={item.photoUrl.smallWatermarked}
                  style={{
                    height: '220px',
                    width: '220px',
                    objectFit: 'cover',
                  }}
                  alt={item._id}
                  onClick={() =>
                    navigate(`/event/${eventParam}/photo/${item._id}`)
                  }
                />
              </div>
            )
          })}
      {!photo.length && notfound && (
        <center>
          <img
            src={err404}
            // style={{ height: '220px', width: '220px' }}
            height="598"
            width="398"
            alt="Nothing"
          />
        </center>
      )}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        // onClick={handleCloseBib}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}

export default ProductBox
