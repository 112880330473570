import React, { useContext, useState } from 'react'
import FilterBox from '../../components/FilterArea'
import Footer from '../../components/_Footer'
import NavBar from '../../components/_Navbar'
import ProductBox from '../../components/Product'
import GlobalContext from '../../context/globalContext'
import { useParams } from 'react-router-dom'
import './style.css'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

function ProductPage() {
  const [resultingPhotos, setResultingPhotos] = useState(undefined)
  const params = useParams()
  const { event, eventname, setEvent } = useContext(GlobalContext)
  const [value, setvalue] = useState(
    eventname
      ? eventname
      : params.eventID.substring(0, params.eventID.length - 25),
  )
  if (!event) setEvent(params.eventID.slice(-24))
  // const navigate = useNavigate()
  const [bib, setBib] = useState(null)
  const [openBib, setOpenBib] = useState(false)

  const handleCloseBib = () => {
    setOpenBib(false)
  }

  //callback for tye bib
  const getBibdata = (data) => {
    setOpenBib(true)
    setBib(data)
  }

  // useEffect(() => {
  //   const timeOutId = setTimeout(() => {
  //     if (value === '' || eventname === '') {
  //       navigate('/')
  //     }
  //   }, 2000)
  //   return () => clearTimeout(timeOutId)

  //   // eslint-disable-next-line
  // }, [value, eventname])
  return (
    <div style={{ position: 'relative' }}>
      <NavBar eventID={params.eventID} />
      <div
        style={{
          height: '1px',
          backgroundColor: '#f0f0f0',
          position: 'relative',
        }}
      >
        {' '}
      </div>{' '}
      <div className=" searchBar flex justify-around items-center border-t-2">
        <div>
          <span className=" font-bold"> Showing 1 - 20 </span> out of 2,356
          Products{' '}
        </div>{' '}
        <div>
          <input
            type="text"
            placeholder="Enter Event Name"
            value={value}
            onChange={(e) => setvalue(e.target.value)}
            className="searchField"
            name=""
            id=""
          />
        </div>{' '}
        {/* <div>
          <span className=" font-bold"> Sort By </span> : time taken{' '}
        </div>{' '} */}
      </div>{' '}
      <div className="FilterArea p-8 flex justify-around">
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBib}
          // onClick={handleCloseBib}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <FilterBox getBibdata={getBibdata} />
        <ProductBox
          bib={bib}
          eventId={event}
          handleCloseBib={handleCloseBib}
          eventParam={params.eventID}
          resultingPhotos={resultingPhotos}
        />{' '}
      </div>{' '}
      <Footer />
    </div>
  )
}

export default ProductPage
