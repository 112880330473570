import axios from 'axios'

// return with axios object
// export const BACKEND_BASE_URL = 'https://stg-api.tamarind.ai'
let BACKEND_BASE_URL
if (process.env.NODE_ENV === 'development') {
  BACKEND_BASE_URL = 'http://localhost:8080'
} else {
  BACKEND_BASE_URL = 'https://stg-api.tamarind.ai'
}
// export const BACKEND_BASE_URL = 'http://localhost:8080'
axios.defaults.baseURL = BACKEND_BASE_URL
axios.defaults.headers['Content-Type'] = 'application/json'
export default axios
//