// import { Route, Routes } from 'react-router-dom'
// import HomePage from './Pages/HomePage'
// import ProductPage from './Pages/ProductPage'
// import './App.css'
// import CustomProduct from './Pages/CustomProduct'
// import CartPage from './Pages/CartPage'
// import Payment from './Pages/Payment'
// import OrderSearchPage from './Pages/OrderSearchPage'
// function App() {
//   return (
//     <Routes>
//       <Route path="/" element={<HomePage />} />
//       <Route path="/event" element={<ProductPage />} />
//       <Route path="/cart" element={<CartPage />} />
//       <Route path="/payment" element={<Payment />} />
//       <Route path="/my-order" element={<OrderSearchPage />} />
//       <Route path="/product/:pid" element={<CustomProduct />} />
//     </Routes>
//   )
// }

import { Route, Routes, useLocation } from 'react-router-dom'
import HomePage from './Pages/HomePage'
import ImageUpload from './Pages/ImageUpload'
import Gallery from './Pages/Gallery/Gallery'
import EventCreate from './Pages/Event_Create'
import EventDetails from './Pages/Event_Details'
import EventDisplay from './Pages/Event_Display'
import Welcome from './Pages/Welcome'
import Analytics from './Pages/Event_Analytics'
import Footer from './components/Footer'
import Navbar from './components/Navbar'
import Header from './components/Header'
import NotFound from './Pages/NotFound'
import OrderSearchPage from './Pages/OrderSearchPage'
// import ImageUpload_1 from './Pages/ImageUpload_1'
import Pricing from './Pages/Event_Pricing'
import ProductPage from './Pages/ProductPage'
import CustomProduct from './Pages/CustomProduct'
import CartPage from './Pages/CartPage'
import { useState } from 'react'
import './App.css'
import useAxios from './hooks/useAxios'
import dummyEventObject from './dummyEventObject'
import SuperTokens, {
  SuperTokensWrapper,
  getSuperTokensRoutesForReactRouterDom,
} from 'supertokens-auth-react'
import ThirdPartyEmailPassword, {
  Github,
  Google,
  Facebook,
  Apple,
} from 'supertokens-auth-react/recipe/thirdpartyemailpassword'
import Session, { SessionAuth } from 'supertokens-auth-react/recipe/session'
import * as reactRouterDom from 'react-router-dom'

function App() {
  const [selectedEvent, setSelectedEvent] = useState(dummyEventObject)
  // const [selectedEvent, setSelectedEvent] = useState(undefined);
  const location = useLocation()

  SuperTokens.init({
    appInfo: {
      // learn more about this on https://supertokens.com/docs/thirdpartyemailpassword/appinfo
      appName: 'Tamarind.ai',
      apiDomain: process.env.REACT_APP_BACKEND_URL,
      websiteDomain: process.env.REACT_APP_FRONTEND_URL,
      apiBasePath: '/auth',
      websiteBasePath: '/auth',
    },
    recipeList: [
      ThirdPartyEmailPassword.init({
        signInAndUpFeature: {
          providers: [
            Github.init(),
            Google.init(),
            Facebook.init(),
            Apple.init(),
          ],
        },
        getRedirectionURL: async (context) => {
          if (context.action === 'SUCCESS') {
            if (context.redirectToPath !== undefined) {
              // we are navigating back to where the user was before they authenticated
              return context.redirectToPath
            }
            return '/org/dashboard'
          }
          return undefined
        },
      }),
      Session.init(),
    ],
  })

  return (
    <div className="outer-container h-screen">
      {location && location.pathname && location.pathname.slice(0, 4) === '/org' && (
        <>
          <Navbar
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
          />
          <Header />
        </>
      )}

      <Routes>
        {getSuperTokensRoutesForReactRouterDom(reactRouterDom)}
        <Route path="/">
          <Route
            index
            element={<HomePage setSelectedEvent={setSelectedEvent} />}
          />
          <Route path="/event/:eventID" element={<ProductPage />} />
          <Route path="*" exact element={<NotFound />} />
        </Route>
        <Route path="/my-order" element={<OrderSearchPage />} />
        <Route
          path="/event/:eventID/photo/:pid"
          exact
          element={<CustomProduct />}
        />
        <Route path="/cart" element={<CartPage />} />

        <Route path={process.env.REACT_APP_ADMIN_URL}>
          <Route
            path="dashboard"
            element={
              <SessionAuth>
                <Welcome />
              </SessionAuth>
            }
          />
          <Route
            path="events"
            element={<EventDisplay setSelectedEvent={setSelectedEvent} />}
          />
          <Route
            path="event/:name/gallery"
            element={
              <SessionAuth>
                <Gallery />
              </SessionAuth>
            }
          />
          <Route
            path="dashboard/events/upload"
            element={
              <SessionAuth>
                <ImageUpload
                  selectedEvent={selectedEvent}
                  setSelectedEvent={setSelectedEvent}
                />
              </SessionAuth>
            }
          />
          <Route
            path="dashboard/events/create"
            element={
              <SessionAuth>
                <EventCreate setSelectedEvent={setSelectedEvent} />
              </SessionAuth>
            }
          />
          <Route
            path="dashboard/events/details"
            element={
              <SessionAuth>
                <EventDetails selectedEvent={selectedEvent} />
              </SessionAuth>
            }
          />
          <Route
            path="dashboard/events/pricing"
            element={
              <SessionAuth>
                <Pricing
                  selectedEvent={selectedEvent}
                  setSelectedEvent={setSelectedEvent}
                />
              </SessionAuth>
            }
          />
          <Route
            path="dashboard/events/analytics"
            element={
              <SessionAuth>
                <Analytics selectedEvent={selectedEvent} />
              </SessionAuth>
            }
          />
          <Route path="*" exact element={<NotFound />} />
        </Route>
      </Routes>
      {location && location.pathname && location.pathname.slice(0, 4) === '/org' && <Footer />}
    </div>
  )
}

export default App
