import React from "react";
import "./styles.css";
const Card = ({ instructionIndex, instructionImage, instructionAction, instructionText }) => {
    return (
        <div className="instructions-card text-center">
            <div className="instructions-index">
                STEP {instructionIndex}
            </div>
            <div className="instructions-image">
                <i className="material-icons-outlined">{instructionImage}</i>
            </div>
            <div className="instructions-action">
                {instructionAction}
            </div>
            <div className="instructions-text">
                {instructionText}
            </div>
        </div>
    );
}

export default Card;