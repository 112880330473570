import axios from '../utils/axios'

export const GetImagesFromEventID = async ({ eventID }) => {
  const { data } = await axios.get(
    `/v1/events/${eventID}/photos`,
  )
  return data.payload
}

export const BIBTagImagesForEvent = async ({ eventID }) => {
  const { data } = await axios.get(`/v1/photos/tag-images/${eventID}`)
  // console.log("Hit tag images for event functionality: ", data);
  return data
}

export const FaceTagImagesForEvent = async ({ eventID }) => {
  const { data } = axios.get(`/v1/events/${eventID}/index-photos`)
  return data
}
