import { useState, useEffect } from 'react';
import axios from 'axios';
const useAxios = ({ url, method, body = null, headers = null }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    const fetchData = () => {
        axios.request({ method, url, headers: JSON.parse(headers), body: JSON.parse(body) })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
    }, [method, url, body, headers]);

    return { data, error, loading };
};

export default useAxios;
