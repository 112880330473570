import React, { useEffect } from "react";
import "./styles.css";
import "../../assets/css/commonPageStyles.css";
import { Navigate } from "react-router-dom";
const Details = ({ selectedEvent = undefined }) => {
    if (!selectedEvent) {
        return (
            <Navigate to="/org/dashboard" />
        )
    }
    else {
        return (
            <div className="page">
                <div className="section-header">{selectedEvent.name}, {selectedEvent.location.city} ({selectedEvent.date})</div>
            </div>
        )
    }
}

export default Details;