import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Footer from '../../components/_Footer'
import NavBar from '../../components/_Navbar'
import './style.css'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { toast, ToastContainer } from 'react-toastify'
import {
  ValidateOrder,
  CreateCheckoutSession,
} from '../../services/cart.service'

function CartPage() {
  let navigate = useNavigate()
  const [items, setItems] = useState()
  const [event, setEvent] = useState()
  const [opendialog, setOpendialog] = useState(false)
  const [totalprice, setTotalprice] = useState()
  const emailRef = useRef(null)
  const paymentFailed = () =>
    toast.error('Payment Failed! Try again later!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    })
  const notify = () =>
    toast.error('Some error occured!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    })

  const handleClickOpen = () => {
    setOpendialog(true)
  }

  const handleClose = () => {
    setOpendialog(false)
  }

  useEffect(() => {
    ;(async () => {
      const searchParams = new URLSearchParams(document.location.search)
      if (searchParams.get('q') === 'failed') {
        paymentFailed()
      }
      let arr = []
      let existing = JSON.parse(localStorage.getItem('cart') || '[]')

      if (existing.length !== 0) {
        setEvent(JSON.parse(existing[0]).eventID)
        for (let i = 0; i < existing.length; i++) {
          // console.log(JSON.parse(existing[i]).product)
          arr.push({
            item: JSON.parse(existing[i]).photoID,
            product: JSON.parse(existing[i]).product,
            type: JSON.parse(existing[i]).type,
            photoUrl: JSON.parse(existing[i]).photoUrl,
          })
        }
      }

      const response = await ValidateOrder({ payload: { cart: arr } })

      if (response.status === 'Success') {
        setTotalprice(response.totPrice)
        setItems(response.cart)
      } else {
        notify()
      }

      // localStorage.clear()
    })()
  }, [])

  const startPayment = async () => {
    let arr = []
    let existing = JSON.parse(localStorage.getItem('cart') || '[]')

    if (existing.length !== 0) {
      for (let i = 0; i < existing.length; i++) {
        arr.push({
          price_data: {
            // currency: 'inr',
            product_data: {
              name: JSON.parse(existing[i]).product,
              metadata: {
                photoID: JSON.parse(existing[i]).photoID,
              },
            },
            // unit_amount: parseInt(totalprice / existing.length) * 100,
          },
          quantity: 1,
        })
      }
    }

    const data = await CreateCheckoutSession({
      customer: emailRef.current.value,
      line_items: arr,
      eventID: event.slice(-24),
      eventName: event.slice(0, -25),
    })
    handleClose()
    window.open(data.checkout_url, '_self')
  }

  return (
    <>
      <NavBar />
      <div className=" cartBg">
        <ToastContainer />
        <div className=" font-semibold text-xl">Cart</div>
        <div className=" flex justify-between flex-wrap mt-5">
          <div className=" Items-Details">
            <div className=" text-base font-semibold pl-4">Items Details</div>
            <div
              style={{ width: '80%', height: '1px', background: '#eaeaea' }}
            ></div>
            {items?.map((item, index) => {
              return (
                <span key={index}>
                  <div className="cartItem flex">
                    <div>
                      <img
                        alt="cartImage"
                        style={{
                          height: '130px',
                          width: '130px',
                          objectFit: 'cover',
                        }}
                        src={item.productImage}
                      />
                    </div>
                    <div className=" flex justify-between ml-5">
                      <div>
                        <div className=" font-semibold text-base">
                          {item.eventName} _ {item.product}
                        </div>
                        <div
                          className=" flex justify-around items-center"
                          style={{ marginTop: '10px' }}
                        >
                          <div>Digital Photo</div>
                          {/* <div className="increase-decrease-div">
                            <span className=" cursor-pointer">-</span>
                            <span>1</span>
                            <span className=" cursor-pointer">+</span>
                          </div> */}
                          {/* <div style={{ color: '#3fa2f7' }}>Delete</div> */}
                        </div>
                        {/* <div className=" mt-10" style={{ color: '#3fa2f7' }}>
                          {' '}
                          + add photo print
                        </div> */}
                      </div>
                      <div className=" ml-40">
                        <div className=" font-black">{item.price}</div>
                        {/* <button
                          className=" mt-16"
                          style={{ color: '#fd4420' }}
                          onClick={() => handleDelete(index)}
                        >
                          Delete
                        </button> */}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      height: '1px',
                      background: '#eaeaea',
                    }}
                  ></div>
                </span>
              )
            })}
          </div>
          <div className=" Rectangle-33 ">
            {items && totalprice && (
              <div className="Order-Summary">
                <div className=" flex justify-between items-center">
                  <div className=" font-medium">Order Summary</div>
                  <div className=" font-black">{items.length} Photos</div>
                </div>
                <div
                  style={{
                    width: '100%',
                    height: '1px',
                    background: '#eaeaea',
                    marginTop: '20px',
                  }}
                ></div>
                <div
                  style={{ color: '#4e4e4e', fontSize: '14px' }}
                  className=" flex justify-between mt-8"
                >
                  <div>Delivery Charges</div>
                  <div style={{ width: '173px' }}>
                    Add your Delivery address at checkout to see delivery
                    charges
                  </div>
                </div>
                <div
                  style={{
                    width: '100%',
                    height: '1px',
                    background: '#eaeaea',
                    marginTop: '20px',
                  }}
                ></div>
                <div className=" text-sm flex justify-between items-center pt-4">
                  <div>Subtotal</div>
                  <div className=" font-black">Rs.{totalprice}</div>
                </div>
                <div
                  style={{
                    width: '100%',
                    height: '1px',
                    background: '#eaeaea',
                    marginTop: '20px',
                  }}
                ></div>
                <div className=" font-black text-2xl flex justify-between items-center pt-4">
                  <div>Total</div>
                  <div>Rs.{totalprice}</div>
                </div>
                <div
                  style={{
                    width: '100%',
                    height: '1px',
                    background: '#eaeaea',
                    marginTop: '20px',
                  }}
                ></div>
                <button className=" buy-btn" onClick={handleClickOpen}>
                  Proceed to buy
                </button>
                <div
                  style={{
                    width: '100%',
                    height: '1px',
                    background: '#eaeaea',
                    marginTop: '20px',
                  }}
                ></div>
                <button className=" back-btn" onClick={() => navigate(-1)}>
                  Back to Events
                </button>
              </div>
            )}
            <Dialog
              maxWidth="md"
              fullWidth={true}
              // fullScreen={fullScreen}
              open={opendialog}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle
                id="responsive-dialog-title"
                sx={{ fontFamily: 'arial' }}
              >
                Enter your Email ID!
              </DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Email Address"
                  type="email"
                  fullWidth
                  variant="standard"
                  inputRef={emailRef}
                />
              </DialogContent>
              <DialogActions>
                <button
                  className="def-button"
                  autoFocus
                  // onClick={handleClose}
                  onClick={startPayment}
                  sx={{ fontFamily: 'arial' }}
                >
                  Save
                </button>
                <button
                  className="def-button"
                  onClick={handleClose}
                  autoFocus
                  sx={{ fontFamily: 'arial' }}
                >
                  Cancel
                </button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default CartPage
