import React from "react";
import "./styles.css";
import "../../assets/css/commonPageStyles.css";
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import InstructionsCard from "../../components/InstructionsCard";
const Welcome = () => {
    const user = useSessionContext();
    console.log("User is: ", user);
    const instructions = [
        {
            "instructionIndex": 1,
            "instructionImage": "cloud_upload",
            "instructionAction": "Upload",
            "instructionText": "Add event details once you create the event. Next, you can upload your photos and get them auto-tagged by Tamarind."
        },
        {
            "instructionIndex": 2,
            "instructionImage": "settings",
            "instructionAction": "Configure",
            "instructionText": `Configure event specific parameters; pricing plans, mail notifications etc., and customize them as you would like.`
        },
        {
            "instructionIndex": 3,
            "instructionImage": "rocket_launch",
            "instructionAction": "Launch",
            "instructionText": `Preview how the events page would like for race participants. Take the event online once all the details are complete.`
        },
        {
            "instructionIndex": 4,
            "instructionImage": "query_stats",
            "instructionAction": "Monitor",
            "instructionText": "Review usage analytics. Track how many views are getting converted to downloads/purchases."
        }
    ]
    return (
        <div className="page">
            <h1>Hello user</h1>
            <div className="image-section">
                <img src="/images/welcome-screen-image.jpg" alt="Image uploading picture." />
            </div>
            <div className="create-instructions-section">
                <div className="section-header">
                    Create your First Event
                </div>
                <div id="create-instructions" className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4">
                    {instructions.map((instruction, index) => {
                        return (
                            <div className="col-span-1" key={index}>
                                <InstructionsCard {...instruction} />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}

export default Welcome;