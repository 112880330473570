import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './styles.css'
import '../../assets/css/commonPageStyles.css'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import TextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import axios from 'axios'
import { useRef } from 'react'
const Create = ({ setSelectedEvent }) => {
  const navigate = useNavigate()
  const emptyEventDetails = {
    name: '',
    date: new Date(),
    type: 'sprint',
    raceType: 'sprint',
    city: '',
    description: '',
  }

  const _errors = {}
  for (let key in emptyEventDetails) {
    _errors[key] = ''
  }

  const [errors, setErrors] = useState(_errors)
  const photographerImage = useRef()
  const successToast = (message = undefined, closeTime = 1000) => {
    return toast.success(message ? message : 'Event changed!', {
      position: 'top-right',
      autoClose: closeTime,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }

  const errorToast = (message = undefined, closeTime = 1000) => {
    return toast.error(
      message ? message : 'Event not changed! Some error occurred.',
      {
        position: 'top-right',
        autoClose: closeTime,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      },
    )
  }
  const [eventDetails, setEventDetails] = useState(emptyEventDetails)
  const [errorsCount, setErrorsCount] = useState(0)

  const maximumEventNameInputLength = process.env.REACT_APP_MAXIMUM_EVENT_NAME_LENGTH ? process.env.REACT_APP_MAXIMUM_EVENT_NAME_LENGTH : 50;
  const maximumEventCityInputLength = process.env.REACT_APP_MAXIMUM_EVENT_CITY_LENGTH ? process.env.REACT_APP_MAXIMUM_EVENT_CITY_LENGTH : 50;

  const handleEventDetails = (e) => {
    const { name, value } = e.target
    switch (name) {
      case 'name':
        if (value.length > maximumEventNameInputLength) {
          if (errors.name.length === 0) {
            setErrorsCount(errorsCount + 1)
            setErrors({
              ...errors,
              ['name']: `Please keep the event name length below ${maximumEventNameInputLength}.`,
            })
          }
        } else if (value.length === 0) {
          setErrorsCount(errorsCount + 1)
          setErrors({ ...errors, ['name']: `Please enter an event name.` })
        } else {
          if (errors.name.length !== 0) {
            setErrorsCount(errorsCount - 1)
            setErrors({ ...errors, ['name']: '' })
          }
        }
        break

      case 'raceType':
        if (value.length === 0) {
          if (errors.raceType.length === 0) {
            setErrorsCount(errorsCount + 1)
            setErrors({ ...errors, ['raceType']: `Please select a race type.` })
          }
        } else {
          setErrorsCount(errorsCount - 1)
          setErrors({ ...errors, ['raceType']: '' })
        }
        break

      case 'city':
        if (value.length > maximumEventCityInputLength) {
          if (errors.city.length === 0) {
            setErrorsCount(errorsCount + 1)
            setErrors({
              ...errors,
              ['city']: `Please keep the event location length below ${maximumEventCityInputLength}.`,
            })
          }
        } else if (value.length === 0) {
          setErrorsCount(errorsCount + 1)
          setErrors({ ...errors, ['city']: `Please enter an event location.` })
        } else {
          if (errors.city.length !== 0) {
            setErrorsCount(errorsCount - 1)
            setErrors({ ...errors, ['city']: '' })
          }
        }
        break
    }
    setEventDetails({ ...eventDetails, [name]: value })
  }

  const resetEventFields = (e) => {
    setEventDetails(emptyEventDetails)
  }

  const handleEventCreation = async (e) => {
    e.preventDefault()
    if (errorsCount > 0) {
      errorToast('Please resolve all errors before submitting the event!', 4000)
      return
    } else {
      try {
        const {
          data: {
            payload: { event: newEvent },
          },
        } = await axios.post(
          process.env.REACT_APP_BACKEND_URL + '/v1/events',
          eventDetails,
        )
        setSelectedEvent(newEvent)
        successToast('Event created! Redirecting...')
        setTimeout(() => {
          navigate('/org/dashboard/events/upload')
        }, [2000])
      } catch (err) {
        console.log(err)
        errorToast('Oh no! Some error occurred while creating the event!', 4000)
        // alert('Oh no! Error...' + err.toString())
      }
    }
  }

  const handleLogoUpload = (e) => {
    e.preventDefault()
    // console.log('file changed')
  }

  return (
    <form className="page create-event-page">
      <section className="create-event grid xl:grid-cols-12 lg:grid-rows-5 md:grid-rows-6 lg:grid-flow-col">
        <section className="create-event-form xl:col-span-9 lg:row-span-5">
          <div className="create-event-header">Edit event details</div>
          <div className="create-event-input-container xl:col-span-9">
            <label className="create-event-input-label" htmlFor="event-name">
              Event name
            </label>
            <br></br>
            <input
              onChange={handleEventDetails}
              type="text"
              id="event-name"
              name="name"
              className="create-event-input"
            // maxLength={maximumEventNameInputLength}
            // noValidate
            />
            <br />
            {errors.name.length > 0 && (
              <>
                <span className="error">{errors.name}</span>
                <br />
              </>
            )}

            <label className="create-event-input-label" htmlFor="event-type">
              Event type
            </label>
            <br></br>
            <select
              onChange={handleEventDetails}
              type="text"
              id="event-type"
              name="raceType"
              className="create-event-input"
              style={{ paddingBottom: 0, paddingTop: 0 }}
            >
              <option value="sprint">Sprint</option>
              <option value="middle">Middle</option>
              <option value="long">Long</option>
              <option value="relays">Relays</option>
            </select>

            <label
              className="create-event-input-label"
              htmlFor="event-location"
            >
              Location
            </label>
            <br></br>
            <input
              onChange={handleEventDetails}
              type="text"
              id="event-location"
              name="city"
              className="create-event-input"
              placeholder="City"
            // maxLength={maximumEventNameInputLength}
            // noValidate
            />
            <br />
            {errors.city.length > 0 && (
              <>
                <span className="error">{errors.city}</span>
                <br />
              </>
            )}

            <label className="create-event-input-label" htmlFor="event-date">
              Event date
            </label>
            <br></br>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                value={eventDetails.date}
                onChange={(newDate) => {
                  setEventDetails({
                    ...eventDetails,
                    date: new Date(newDate.$d),
                  })
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    sx={{
                      input: {
                        fontSize: '14px',
                        fontFamily: 'Lexend',
                        fontStyle: 'normal',
                        fontWeight: '400',
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            <br />
            <label
              className="create-event-input-label"
              htmlFor="event-description"
            >
              Description
            </label>
            <br></br>
            <textarea
              onChange={handleEventDetails}
              type="text"
              id="event-description"
              name="description"
              className="create-event-input"
              placeholder="Type here..."
            ></textarea>
          </div>
          {/* <div className="create-event-logo-upload-container xl:col-span-3"> */}
          {/* <div className="create-event-input-label">Album cover</div>
            <div className="upload-logo event-photographer">
              <img src="/images/event-photographer-default.png" alt="" />
            </div>
            <div className="create-event-input-label">Event logo</div>
            <input
              type="file"
              className="upload-logo event-logo"
              style={{
                backgroundImage: 'url(/images/event-logo-default.png)',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            /> */}
          {/* <input type="file" className="upload-logo" style={{
								zIndex: "-1"
							}} />
						</div> */}
          {/* <img src="" alt="" />
						<input type="image" src="img_submit.gif" alt="Submit" width="48" height="48"></input> */}
          {/* <img className="upload-logo" src="/images/event-logo-default.png" alt="" /> */}
          {/* </input> */}
          {/* </div> */}
        </section>
        <section className="create-event-buttons xl:col-span-3 xl:col-start-10 lg:row-span-5 lg:pt-[28rem]">
          <button
            onClick={resetEventFields}
            className="text-center create-event-cancel-button"
            type="reset"
          >
            Cancel
          </button>
          &nbsp; &nbsp;
          <button
            onClick={handleEventCreation}
            className="text-center create-event-submit-button"
            type="submit"
          >
            Save
          </button>
        </section>
      </section>
      <ToastContainer />
    </form>
  )
}

export default Create
