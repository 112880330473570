// module.exports = {
//     "name": "test-event",
//     "date": 2022,
//     "location": { "city": "Mumbai" },
//     "photos": [
//         {
//             "name": "photo-1.jpg",
//             "tagStatus": "TAGGED",
//             "tags": [123, 234, 345, 456, 567, 678],
//             "visibility": false
//         },
//         {
//             "name": "photo-2.jpg",
//             "tagStatus": "TAGGED",
//             "tags": [123, 234, 345, 456, 567, 678],
//             "visibility": true
//         },
//         {
//             "name": "photo-3.jpg",
//             "tagStatus": "NOT TAGGED",
//             "tags": [123, 234, 345, 456, 567, 678],
//             "visibility": true
//         },
//         {
//             "name": "photo-4.jpg",
//             "tagStatus": "TAGGED",
//             "tags": [123, 234, 345, 456, 567, 678],
//             "visibility": false
//         },
//         {
//             "name": "photo-5.jpg",
//             "tagStatus": "NOT TAGGED",
//             "tags": [123, 234, 345, 456, 567, 678],
//             "visibility": true
//         },
//         {
//             "name": "photo-6.jpg",
//             "tagStatus": "NOT TAGGED",
//             "tags": [123, 234, 345, 456, 567, 678],
//             "visibility": false
//         },
//         {
//             "name": "photo-7.jpg",
//             "tagStatus": "ERROR",
//             "tags": [123, 234, 345, 456, 567, 678],
//             "visibility": false
//         },
//         {
//             "name": "photo-8.jpg",
//             "tagStatus": "TAGGED",
//             "tags": [123, 234, 345, 456, 567, 678],
//             "visibility": true
//         },
//         {
//             "name": "photo-8.jpg",
//             "tagStatus": "ERROR",
//             "tags": [123, 234, 345, 456, 567, 678],
//             "visibility": false
//         },
//         {
//             "name": "photo-9.jpg",
//             "tagStatus": "TAGGED",
//             "tags": [123, 234, 345, 456, 567, 678],
//             "visibility": true
//         },
//         {
//             "name": "photo-10.jpg",
//             "tagStatus": "TAGGED",
//             "tags": [123, 234, 345, 456, 567, 678],
//             "visibility": false
//         },
//     ],
//     "pricingInfo": [
//         {
//             title: "Digital",
//             digital: 400
//         },
//         {
//             title: "6 x 6 Photo Print",
//             physical: 400
//         },
//         {
//             title: "6 x 6 Photo Print",
//             physical: 400
//         },
//         {
//             title: "6 x 6 Photo Print",
//             physical: 400
//         },
//         {
//             title: "6 x 6 Photo Print",
//             physical: 400
//         },
//         {
//             title: "6 x 6 Photo Print",
//             physical: 400
//         },
//         {
//             title: "6 x 6 Photo Print",
//             physical: 400
//         }
//     ]
// }
module.exports = undefined;