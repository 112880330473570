import React, { useRef, useState, useEffect } from 'react'
import './style.css'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import imageCompression from 'browser-image-compression'
import { GenerateUploadURL } from '../../services/aws.service'
import { SearchPhotos } from '../../services/event.service'
import { toast, ToastContainer } from 'react-toastify'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'
const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
}

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
}

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
}

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
}

const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
}

function FilterBox({
  getBibdata,
  eventID,
  resultingPhotos,
  setResultingPhotos,
}) {
  const color = [
    '#ff6969',
    '#ffc700',
    '#ffb2fc',
    '#00ff0a',
    '#00a3ff',
    '#99899e',
    '#ffffff',
    '#000000',
    '#ff8a00',
    '#7d67a0',
    '#676b4e',
    '#4a5787',
    '#f0c7dd',
    '#ddf893',
    '#6c0000',
    '#7ee2dc',
  ]
  const [pictures, setPictures] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [opendialog, setOpendialog] = useState(false)
  const [compressedFiles, setCompressedFiles] = useState([])
  const [eventPhotos, setEventPhotos] = useState([])
  const [eventPhotosError, setEventPhotosError] = useState(false)
  const [eventPhotosLoading, setEventPhotosLoading] = useState(true)
  const toastId = useRef(null)
  function Previews() {
    const { getRootProps, getInputProps } = useDropzone({
      accept: {
        'image/jpeg': [],
        'image/png': [],
        'image/jpg': [],
      },
      maxSize: 26214400,
      maxFiles: 2,
      onDrop: async (acceptedFiles) => {
        if (acceptedFiles.length > 1) {
          toastId.current = toast.error('Please upload only one selfie!', {
            position: 'top-center',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: 'light',
          })
          return
        }
        let compressedImages = []
        for (let i of acceptedFiles) {
          setPictures((pehle) => [...pehle, i])
          let compressedFile = await imageCompression(i, options)
          compressedImages.push(compressedFile)
        }

        setCompressedFiles(compressedImages)
        setPictures(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            }),
          ),
        )
      },
    })

    const thumbs = pictures.map((file) => (
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img
            src={file.preview}
            style={img}
            // Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(file.preview)
            }}
            alt=""
          />
        </div>
      </div>
    ))
    useEffect(() => {
      // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
      return () => pictures.forEach((file) => URL.revokeObjectURL(file.preview))
    }, [])

    return (
      <section className="container">
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select a file.</p>
        </div>
        <aside style={thumbsContainer}>{thumbs}</aside>
      </section>
    )
  }
  const start = () =>
    (toastId.current = toast.loading('Uploading', {
      position: 'top-center',
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: 'light',
    }))

  const confirmUpload = async () => {
    start()
    let filedata = new FormData()
    let filenames = []

    for (var i = 0; i < pictures.length; i++) {
      filenames.push(pictures[i].name)
      filedata.append('images', compressedFiles[i])
    }
    filedata.append('size', pictures.length)
    // console.log("Pictures are: ", pictures);
    const awsresponse = await GenerateUploadURL({
      filenames,
      quantity: pictures.length,
      eventID,
    })
    toast.update(toastId.current, {
      render: 'Fetching Cloudinary URL!',
      type: 'info',
      isLoading: true,
    })

    toast.update(toastId.current, {
      render: 'Saving watermarked URLs!',
      type: 'info',
      isLoading: true,
    })

    for (let i = 0; i < pictures.length; i++) {
      try {
        if (awsresponse.url.length > 0) {
          // console.log(awsresponse.url)
          for (let i = 0; i < pictures.length; i++) {
            const myHeaders = new Headers({ 'Content-Type': pictures[i].type })
            const response = await fetch(awsresponse.url[i], {
              method: 'PUT',
              headers: myHeaders,
              body: pictures[i],
            })
            console.log(awsresponse.params[i])
            const resultingImages = await SearchPhotos({
              image: {
                Name: awsresponse.params[i].Key,
              },
            })
            console.log('ext img id : ', resultingImages)
            // console.log("AWS response is: ", response);
            // const resultingImages = await axios.post(
            //   `${process.env.REACT_APP_BACKEND_URL}/v1/events/${eventID}/photos/search`,
            //   {
            //     image: {
            //       Name: awsresponse.params[i].Key,
            //     },
            //   },
            // )
            const externalImageIDs = resultingImages.data.payload.matchingPhotos.FaceMatches.map(
              (face) => face.Face.ExternalImageId,
            )
            toast.update(toastId.current, {
              render: 'Getting matching faces!',
              type: 'info',
              isLoading: true,
            })
            const matchingPhotos = []

            for (let id of externalImageIDs) {
              const {
                data: {
                  payload: { photo },
                },
              } = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/v1/photos/${id}`,
              )
              const actualPhoto = (
                <div
                  key={photo._id}
                  style={{
                    display: 'inline-block',
                    padding: '10px',
                    marginBottom: '10px',
                  }}
                >
                  <img
                    src={photo.photoUrl.smallWatermarked}
                    style={{
                      height: '220px',
                      width: '220px',
                      objectFit: 'cover',
                    }}
                    alt={photo._id}
                    // onClick={() => navigate(`/event/${eventID}/photo/${photo._id}`)}
                  />
                </div>
              )
              matchingPhotos.push(actualPhoto)
            }
            // console.log("Matching photos are: ", matchingPhotos);
            setResultingPhotos(matchingPhotos)
          }
        } else {
          // console.log(awsresponse)
        }
        toast.update(toastId.current, {
          render: 'Completed!',
          type: 'success',
          isLoading: false,
        })
      } catch (err) {
        toast.update(toastId.current, {
          render: 'Could not complete the uploading!',
          type: 'error',
          isLoading: false,
        })
      }
      // })
    }

    handleClose()
  }
  const handleClickOpen = () => {
    setOpendialog(true)
  }

  const handleClose = () => {
    setOpendialog(false)
    setPictures([])
    setCompressedFiles([])
  }

  const bibRef = useRef(null)
  return (
    <div
      className="filter-box"
      style={{ padding: '12px', position: 'relative' }}
    >
      <ToastContainer />
      <div
        className="flex justify-between items-center"
        style={{ padding: '20px 12px' }}
      >
        <div className=" font-bold">Filters</div>
        <div className=" text-cyan-500">Reset</div>
      </div>
      <div className="search-field">
        <input
          type="text"
          placeholder="Enter bib number"
          style={{ paddingLeft: '12px' }}
          className="search-input"
          ref={bibRef}
        />
        <div className=" mt-2">
          <button
            style={{
              background: '#fd4420',
              color: '#fff',
              height: '30px',
              width: '150px',
              borderRadius: '6px',
            }}
            onClick={() => {
              getBibdata(bibRef.current.value)
            }}
          >
            Find
          </button>
        </div>
      </div>
      <div style={{ height: '1px', backgroundColor: '#f0f0f0' }}></div>
      <div style={{ padding: '12px', marginTop: '23px' }}>
        <div className="flex justify-between items-center">
          <span className=" font-bold">Timeframe</span>
          <span>9:00 - 13:00</span>
        </div>
        <div style={{ width: '100%', marginTop: '22px' }}>
          <input
            type="range"
            style={{ width: '100%', accentColor: '#fd4420', outline: 'none' }}
            name=""
            id=""
          />
        </div>
      </div>
      <div style={{ height: '1px', backgroundColor: '#f0f0f0' }}></div>
      <div style={{ padding: '12px' }}>
        <div className=" font-bold">Photographers</div>
        <div className=" flex flex-col pt-4">
          <div
            className=" flex justify-between items-center"
            style={{ width: '60%' }}
          >
            <input type="checkbox" id="" />
            <span>Photographer 1</span>
          </div>
          <div
            className=" flex justify-between mt-2 items-center"
            style={{ width: '60%' }}
          >
            <input type="checkbox" id="" />
            <span>Photographer 2</span>
          </div>
          <div
            className=" flex justify-between mt-2 items-center"
            style={{ width: '60%' }}
          >
            <input type="checkbox" id="" />
            <span>Photographer 3</span>
          </div>
          <div
            className=" flex justify-between mt-2 items-center"
            style={{ width: '60%' }}
          >
            <input type="checkbox" id="" />
            <span>Photographer 4</span>
          </div>
        </div>
      </div>
      <div style={{ height: '1px', backgroundColor: '#f0f0f0' }}></div>
      <div style={{ padding: '12px' }}>
        <div className=" font-bold">Jersey colour</div>
        <div className=" flex justify-around items-center flex-wrap">
          {color.map((item) => {
            return (
              <div
                key={item}
                style={{
                  height: '30px',
                  width: '30px',
                  backgroundColor: item,
                  marginTop: '8px',
                  borderRadius: '2px',
                  border: 'solid 0.5px #c4c4c4',
                }}
              ></div>
            )
          })}
        </div>
      </div>
      <div
        style={{ height: '1px', backgroundColor: '#f0f0f0', marginTop: '20px' }}
      ></div>
      <div style={{ padding: '12px' }}>
        <span className=" font-bold">Tags</span>
        <div className=" flex flex-col pt-4">
          <div className=" flex  mt-2 items-center" style={{ width: '60%' }}>
            <input type="checkbox" id="" />
            <span className=" ml-8">Awards</span>
          </div>
          <div className=" flex  mt-2 items-center" style={{ width: '60%' }}>
            <input type="checkbox" id="" />
            <span className=" ml-8">Lost & found</span>
          </div>
          <div className=" flex  mt-2 items-center" style={{ width: '60%' }}>
            <input type="checkbox" id="" />
            <span className=" ml-8">Finish</span>
          </div>
          <div className=" flex mt-2 items-center" style={{ width: '60%' }}>
            <input type="checkbox" id="" />
            <span className=" ml-8">Photo booth</span>
          </div>
        </div>
        <div
          style={{
            height: '1px',
            backgroundColor: '#f0f0f0',
            marginTop: '20px',
          }}
        ></div>
        <div style={{ padding: '12px' }}>
          <div className=" font-bold text-cyan-500">Advanced</div>
          <div style={{ fontSize: '14px', color: '#4f4f4f' }}>
            Upload a selfie to find your photos
          </div>
          <div
            className=" flex flex-col justify-center items-center"
            style={{
              height: '170px',
              width: '100%',
              border: 'dotted 1px #c4c4c4',
              marginTop: '15px',
              borderRadius: '8px',
            }}
          >
            <div style={{ fontSize: '14px' }}>Drag & drop your selfie here</div>
            <div className="mt-2">OR</div>
            <div className="mt-2">
              <button
                onClick={handleClickOpen}
                style={{
                  background: '#fd4420',
                  color: '#fff',
                  height: '30px',
                  width: '150px',
                  borderRadius: '6px',
                }}
              >
                Choose file
              </button>
            </div>
            <Dialog
              maxWidth="lg"
              fullWidth={true}
              // fullScreen={fullScreen}
              open={opendialog}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle
                id="responsive-dialog-title"
                sx={{ fontFamily: 'arial' }}
              >
                {`Upload your selfie!`}
              </DialogTitle>
              <DialogContent>
                <Previews />
              </DialogContent>
              <DialogActions>
                <button
                  className="def-button"
                  autoFocus
                  // onClick={handleClose}
                  onClick={
                    pictures.length > 0
                      ? () => {
                          confirmUpload()
                        }
                      : null
                  }
                  sx={{ fontFamily: 'arial' }}
                >
                  Upload
                </button>
                <button
                  className="def-button"
                  onClick={handleClose}
                  autoFocus
                  sx={{ fontFamily: 'arial' }}
                >
                  Cancel
                </button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FilterBox
