import axios from '../utils/axios'

export const ValidateOrder = async ({ payload }) => {
  const { data } = await axios.post('/v1/cart/validate-order', {
    payload,
  })
  return data.payload
}

export const CreateCheckoutSession = async ({
  customer,
  line_items,
  eventID,
  eventName,
}) => {
  const { data } = await axios.post('/v1/payments/checkout-session', {
    customer,
    line_items,
    eventID,
    eventName,
  })
  return data.payload
}
