import React, { useRef, useState, useEffect } from 'react'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import NavBar from '../../components/_Navbar'
import Footer from '../../components/_Footer'
import './style.css'
import { UserSpecificOrders } from '../../services/order.service'
import moment from 'moment'
import { toast, ToastContainer } from 'react-toastify'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { FulfillOrder } from '../../services/photo.service'

function OrderSearchPage() {
  const notify = () =>
    toast.success('Payment was successful!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    })

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search)
    if (searchParams.get('q') === 'success') {
      localStorage.clear()
      notify()
    }
  }, [])

  const emailRef = useRef(null)
  const [orders, setOrders] = useState([])

  const getAllOrders = async () => {
    const orders = await UserSpecificOrders({
      email: emailRef.current.value,
    })
    // console.log(orders)
    setOrders(orders)
  }

  const generateZipFromCloud = (urls) => {
    let filename = 'YourImages'

    const zip = new JSZip()
    const folder = zip.folder('project')
    urls.forEach((url) => {
      const myHeaders = new Headers({ 'Content-Type': 'image/*' })
      const blobPromise = fetch(url, {
        headers: myHeaders,
        method: 'GET',
      }).then(function (response) {
        console.log({ response })
        if (response.status === 200 || response.status === 0) {
          return Promise.resolve(response.blob())
        } else {
          return Promise.reject(new Error(response.statusText))
        }
      })
      const name = url.substring(url.lastIndexOf('/'))
      folder.file(name, blobPromise)
    })

    zip
      .generateAsync({ type: 'blob' })
      .then((blob) => saveAs(blob, filename))
      .catch((e) => console.log(e))
  }

  const downloadZip = async (index) => {
    const data = await FulfillOrder({ orderID: orders[index]._id })
    console.log(data)
    generateZipFromCloud(data)
  }

  return (
    <div>
      <NavBar />
      <div
        className="p-12"
        style={{ minHeight: '80vh', backgroundColor: '#f0f0f0' }}
      >
        <ToastContainer />
        <div className="imgBG flex flex-col justify-center items-center overflow-hidden ">
          <div className="Rectangle-4 relative">
            <h1 className=" text-xl font-semibold text-center text-white">
              Find Past orders
            </h1>
            <p className=" text-sm text-white text-left mt-4 mb-6">
              Search your order status here to re-download digital pictures, or
              check shipping status and order details.
            </p>
            <div>
              <input
                type="text"
                className=" field "
                placeholder="Enter Email"
                ref={emailRef}
              />

              <button className="homebtn" onClick={getAllOrders}>
                Search
              </button>
            </div>
          </div>
        </div>
        {orders && (
          <table class="rwd-table" style={{ marginTop: '20px' }}>
            <tbody>
              <tr>
                <th>Event Name</th>
                <th>Creation Date</th>
                <th>Fulfilled</th>
                <th>Payment Status</th>
                <th>Total Price</th>
                <th>Download Zip</th>
              </tr>
              {orders.map((item, index) => {
                return (
                  <tr key={index}>
                    <td data-th="Supplier Code">{item.eventName}</td>
                    <td data-th="Invoice Date">
                      {moment(item.createdAt).format('YYYY-MM-DD')}
                    </td>
                    <td data-th="Due Date">
                      <span
                        className={`photo-tag-status photo-tag-status-${
                          item.fulfilment_status ? 'SUCCESS' : 'FAILED'
                        }`}
                      >
                        {item.fulfilment_status ? 'SUCCESS' : 'FAILED'}
                      </span>
                    </td>
                    <td data-th="Net Amount">
                      {' '}
                      <span
                        className={`photo-tag-status photo-tag-status-${
                          item.payment_status ? 'SUCCESS' : 'PENDING'
                        }`}
                      >
                        {item.payment_status ? 'SUCCESS' : 'PENDING'}
                      </span>
                    </td>
                    <td data-th="Net Amount">
                      Rs.<span> </span>
                      {item.items
                        .map((item) => item.price)
                        .reduce((prev, next) => prev + next)}
                    </td>
                    <td data-th="Net Amount">
                      <button
                        className="downloadbtn"
                        onClick={() => downloadZip(index)}
                      >
                        <CloudDownloadIcon />
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
      </div>

      <Footer />
    </div>
  )
}

export default OrderSearchPage
