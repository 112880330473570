import React from 'react'
import './styles.css'
import '../../assets/css/commonPageStyles.css'
import useAxios from '../../hooks/useAxios'
import MoreHorizOutlined from '@mui/icons-material/MoreHorizOutlined'
import { Switch } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
const Display = ({ setSelectedEvent = () => { } }) => {
  const navigate = useNavigate()
  const { data: events } = useAxios({
    url: `${process.env.REACT_APP_BACKEND_URL}/v1/events/allevents`,
    method: 'GET',
  })

  const toggleEventVisibility = (event) => {
    // alert('Toggled visibility for event: ' + event.name)
  }

  const handleOptions = (event) => {
    // alert('Handle options called for event: ' + event.name)
  }

  const toggleCheckAllEvents = (e) => {
    const allEvents = document.querySelectorAll('.event-checkbox')
    if (e.target.checked) {
      allEvents.forEach((event) => {
        event.checked = true
      })
    } else {
      allEvents.forEach((event) => {
        event.checked = false
      })
    }
  }

  // const getCellValue = (tr, idx) => tr.children[idx].innerText || tr.children[idx].textContent;

  // const comparer = (idx, asc) => (a, b) => ((v1, v2) =>
  //     v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ?
  //         v1 - v2 :
  //         v1.toString().localeCompare(v2)
  // )(getCellValue(asc ? a : b, idx), getCellValue(asc ? b : a, idx));

  // const sortColumn = (e) => {
  //     const table = e.target.closest('table');
  //     const tbody = table.querySelector('tbody');
  //     Array.from(tbody.querySelectorAll('tr'))
  //         .sort(comparer(Array.from(e.target.parentNode.children).indexOf(e.target), sortingAsc = !sortingAsc))
  //         .forEach(tr => tbody.appendChild(tr));
  // }

  const sortTable = (table, col, reverse) => {
    var tb = table.tBodies[0], // use `<tbody>` to ignore `<thead>` and `<tfoot>` rows
      tr = Array.prototype.slice.call(tb.rows, 0), // put rows into array
      i
    reverse = -(+reverse || -1)
    tr = tr.sort(function (a, b) {
      // sort rows
      return (
        reverse * // `-1 *` if want opposite order
        a.cells[col].textContent
          .trim() // using `.textContent.trim()` for test
          .localeCompare(b.cells[col].textContent.trim())
      )
    })
    for (i = 0; i < tr.length; ++i) tb.appendChild(tr[i]) // append each row in order
  }

  const convertDateFormat = (date) => {
    const _date = new Date(date)
    if (_date instanceof Date && isFinite(_date.getTime())) {
      // date is valid
      const dateString =
        (_date.getDate() > 9 ? _date.getDate() : '0' + _date.getDate()) +
        '/' +
        (_date.getMonth() > 8
          ? _date.getMonth() + 1
          : '0' + (_date.getMonth() + 1)) +
        '/' +
        _date.getFullYear()
      return dateString
    } else {
      // date is not valid
      return 'undefined'
    }
  }

  const makeSortable = (table) => {
    var th = table.tHead,
      i
    th && (th = th.rows[0]) && (th = th.cells)
    if (th) i = th.length
    else return // if no `<thead>` then do nothing
    while (--i >= 0)
      (function (i) {
        var dir = 1
        th[i].addEventListener('click', function () {
          sortTable(table, i, (dir = 1 - dir))
        })
      })(i)
  }

    ; (function makeAllSortable(parent) {
      parent = parent || document.body
      var t = parent.getElementsByTagName('table'),
        i = t.length
      while (--i >= 0) makeSortable(t[i])
    })()

  const visibilityLabel = { inputProps: { 'aria-label': 'Visibility' } }

  return (
    events && (
      <div className="page">
        <div className="section-header">Events</div>
        <div className="table">
          {/* <section className="table-header flex items-center" style={{ width: '100%' }}>
                    <div className="table-header-section flex">Photo Upload</div>
                    <div className="table-header-section flex">
                        {event.photos.length} Photos uploaded, {event.photos.filter(photo => photo.tagStatus === "TAGGED").length} Photos tagged
                    </div>
                    <div className="table-header-section grid grid-cols-12 space-around flex">
                        <button className="table-header-section-button col-span-4 col-start-2">System Tag</button>
                        <button className="table-header-section-button col-span-4 col-start-7" onClick={this.toggleUploadImageStatus}>Upload</button>
                    </div>
                </section> */}
          <table>
            <thead>
              <tr>
                <th>
                  <input type="checkbox" onChange={toggleCheckAllEvents} />
                </th>
                <th>Event name</th>
                <th>Event year</th>
                <th>Event type</th>
                <th>Date created</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {events.payload.events.map((event, index) => {
                const eventCreationDate = convertDateFormat(event.createdAt)
                return (
                  <tr
                    id={`event-${index}`}
                    key={index}
                    className="item"
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <td>
                      <input
                        className="event-checkbox"
                        type="checkbox"
                        onChange={(e) => { }}
                      />
                    </td>

                    <td
                      onClick={(e) => {
                        setSelectedEvent(event)
                        navigate('/org/dashboard/events/upload')
                        // return redirect('/');
                      }}
                      className="event-name"
                    >
                      <Link to="/">
                        {event.name ? event.name : `undefined-${index}`}
                      </Link>
                    </td>
                    <td>
                      {event.date
                        ? `${new Date(event.date).getFullYear()}`
                        : `undefined-${index}`}
                    </td>
                    <td>
                      {event.raceType ? event.raceType : `undefined-${index}`}
                    </td>
                    <td>{event.createdAt ? eventCreationDate : 'undefined'}</td>
                    <td>
                      <button
                        onClick={(e) => {
                          e.preventDefault()
                          handleOptions(event)
                        }}
                      >
                        <MoreHorizOutlined fontSize="small" />
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  )
}

export default Display
