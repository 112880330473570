import React, { useState, useEffect, useContext } from 'react'
import './style.css'
import '../../assets/css/commonPageStyles.css'
import Footer from '../../components/_Footer'
import NavBar from '../../components/_Navbar'
import GlobalContext from '../../context/globalContext'
import { useNavigate } from 'react-router-dom'
import { SearchEvent } from '../../services/event.service'

function HomePage() {
  const [query, setQuery] = useState('')
  // const [flag, setFlag] = useState(true)
  const [displayMessage, setDisplayMessage] = useState([])
  const [visible, setvisible] = useState(false)
  const [msg, setmsg] = useState('')
  const { event, eventname, setEvent, setEventname } = useContext(GlobalContext)
  const navigate = useNavigate()
  useEffect(() => {
    if (query === msg) {
      return
    }
    const timeOutId = setTimeout(() => {
      if (query) {
        FetchData()
      } else {
        setvisible(false)
        return
      }
    }, 2000)
    return () => clearTimeout(timeOutId)
    // eslint-disable-next-line
  }, [query])
  const FetchData = () => {
    SearchEvent({ query, size: 10 })
      .then((data) => {
        setDisplayMessage(data.finresult)
        setvisible(true)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
    <div style={{ minHeight: '80vh', backgroundColor: '#f0f0f0' }}>
      <NavBar />
      <div className="imgBG flex flex-col justify-center items-center overflow-hidden ">
        <div className="Hero-Text ">Remember Your Race Experience...</div>
        <div className="Rectangle-4 relative">
          <input
            type="text"
            className=" field "
            value={query}
            placeholder="Search for an Event..."
            onChange={(e) => setQuery(e.target.value)}
          />

          <button
            className="homebtn"
            onClick={() => {
              if (msg) {
                navigate(`/event/${event}`)
              }
            }}
          >
            Find event
          </button>
        </div>
        <div
          className={` absolute optionarea  ${visible ? 'display: flex' : 'hidden'
            }`}
        >
          {displayMessage.map((items) => {
            return (
              <div
                key={items._id}
                className=" cursor-pointer mb-2"
                onClick={() => {
                  setvisible(false)
                  setQuery(
                    items.name +
                    ' ' +
                    items.location.city +
                    ' ' +
                    new Date(items.date).toLocaleString('default', {
                      month: 'long',
                    }) +
                    ' ' +
                    new Date(items.date).toLocaleString('default', {
                      year: 'numeric',
                    }),
                  )
                  setmsg(
                    items.name +
                    ' ' +
                    items.location.city +
                    ' ' +
                    new Date(items.date).toLocaleString('default', {
                      month: 'long',
                    }) +
                    ' ' +
                    new Date(items.date).toLocaleString('default', {
                      year: 'numeric',
                    }),
                  )
                  setEvent(items._id)
                  setEventname(items.name)
                }}
              >
                {items.name} {items.location.city}
                {'  '}
                {new Date(items.date).toLocaleString('default', {
                  month: 'long',
                })}
                {'  '}
                {new Date(items.date).toLocaleString('default', {
                  year: 'numeric',
                })}
              </div>
            )
          })}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default HomePage
