import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Logo from '../../assets/logo/logo_800px.png'
import CartIcon from '../../assets/shopping_cart.svg'
function NavBar(props) {
  const navigate = useNavigate()
  const handleNav = () => {
    document.getElementById('mobile-menu').classList.toggle('hidden')
  }
  let eventID
  let existing = JSON.parse(localStorage.getItem('cart') || '[]')

  if (existing.length !== 0) {
    eventID = JSON.parse(existing[0]).eventID
  }
  return (
    <nav className="bg-white border-gray-200 px-2 fixed w-full sm:px-20 py-2.5 z-50 ">
      <div className="container flex flex-wrap justify-between items-center mx-auto">
        <Link to="/" className="flex items-center">
          <img
            src={Logo}
            className="mr-3 h-6 sm:h-9"
            style={{ height: '22px', width: '22px' }}
            alt="tamarind logo"
          />
          <span
            className="self-center text-base font-semibold whitespace-nowrap"
            style={{ color: '#fd4420' }}
          >
            Tamarind
          </span>
        </Link>
        <button
          onClick={handleNav}
          type="button"
          className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400"
          aria-controls="mobile-menu"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              // fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              // clip-rule="evenodd"
            ></path>
          </svg>
          <svg
            className="hidden w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              // fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              // clip-rule="evenodd"
            ></path>
          </svg>
        </button>
        <div className="hidden w-full md:block md:w-auto" id="mobile-menu">
          <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm">
            <li style={{ fontSize: '12px' }}>
              <Link
                to="/"
                className="block py-2 pr-2 pl-3 text-gray-700 font-normal uppercase "
                aria-current="page"
              >
                Home
              </Link>
            </li>
            <li style={{ fontSize: '12px' }}>
              <Link
                to="#"
                className="block py-2 pr-2 pl-3 text-gray-700 font-normal uppercase   "
              >
                About
              </Link>
            </li>
            <li style={{ fontSize: '12px' }}>
              <Link
                to="#"
                className="block py-2 pr-2 pl-3 text-gray-700 font-normal  uppercase  "
              >
                Contact
              </Link>
            </li>
            <li style={{ fontSize: '12px' }}>
              <Link
                to="/event"
                className="block py-2 pr-2 pl-3 text-gray-700 font-normal  uppercase  "
              >
                Events
              </Link>
            </li>
            <li style={{ fontSize: '12px' }}>
              <Link
                to="/my-order"
                className="block py-2 pr-2 pl-3 text-gray-700 font-normal uppercase "
              >
                My Orders
              </Link>
            </li>
            <li>
              <img
                src={CartIcon}
                className=" cursor-pointer"
                style={{ height: '30px', width: '30px' }}
                alt="Cart Icon"
                onClick={() => navigate(`/cart`)}
              />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default NavBar
