import axios from '../utils/axios'

export const GenerateUploadURL = async ({
  filenames,
  quantity,
  eventID = undefined,
}) => {
  try {
    const { data } = await axios.post('/v1/photos/presigned-url', {
      filenames,
      quantity,
      eventID,
    })
    // console.log("GenerateUploadURLSelfie called successfully: ", data);
    return data.payload
  } catch (err) {
    console.log(err)
    return err
  }
}

export const PersistImage = async ({
  urls,
  eventID,
  imageNames,
  watermarked = undefined,
  s3ImageIds,
}) => {
  // console.log("PERSIST IMAGE CALLED: ", urls,
  //   eventID,
  //   imageNames,
  //   watermarked)
  if (watermarked === undefined) {
    watermarked = []
  }
  if (watermarked.length === 0) {
    for (let url of urls) {
      watermarked.push('')
    }
  }
  const { data } = await axios.post('/v1/photos', {
    urls,
    eventID,
    imageNames,
    watermarked,
    s3ImageIds,
  })
  // console.log("Persist image data is: ", data);
  return data.payload
}

export const GetEventSpecificImages = async ({ name }) => {
  const { data } = await axios.get(`/photo/images?event=${name}`)
  return data.payload
}

export const GetCloudinarySignature = async () => {
  const { data } = await axios.get('/v1/photos/cloudinary-url')
  return data.payload
}

export const GetClodinaryUrls = async ({ url, formData }) => {
  const { data } = await axios.post(url, formData)
  return data
}
