import axios from '../utils/axios'

export const SearchEvent = async ({ query, size }) => {
  const { data } = await axios.get(
    `/v1/search/event?query=${query}&size=${size}`,
  )
  return data.payload
}

export const GetEventGalleryFromId = async ({
  pageNumber,
  pageSize,
  eventID,
}) => {
  const { data } = await axios.get(
    `/v1/events/${eventID}/dashboard?pageNumber=${pageNumber}&pageSize=${pageSize}`,
  )
  return data.payload
}

export const SearchBibData = async ({
  pageNumber,
  pageSize,
  eventID,
  body,
}) => {
  const { data } = await axios.post(
    `/v1/events/${eventID}/photos/search?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    body,
  )
  return data.payload
}

export const GetEventNameFromID = async ({ eventID }) => {
  const { data } = await axios.get(`/v1/events/event-name/${eventID}`)
  return data.payload
}

export const GetEventDetailsFromPhoto = async ({ pid }) => {
  const { data } = await axios.get(`/v1/photos/event-detail-from-photo/${pid}`)
  return data.payload
}

export const SearchPhotos = async ({ eventID, image }) => {
  console.log(image)
  const { data } = await axios.post(`/v1/events/${eventID}/photos/search`, {
    image,
  })
  return data.payload
}
