import React, { useState, useEffect, useRef } from 'react'
import './styles.css'
import '../../assets/css/commonPageStyles.css'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import useAxios from '../../hooks/useAxios'
const Pricing = ({ selectedEvent, setSelectedEvent }) => {
  const user = useSessionContext();
  const { data: businessAccount, pending, error } = useAxios({ method: 'GET', url: `${process.env.REACT_APP_BACKEND_URL}/v1/business/account/${user.userId}` });
  const titleInputReference = useRef(undefined)
  const priceInputReference = useRef(undefined)
  const [editMode, setEditMode] = useState(false)
  const [editIndex, setEditIndex] = useState(undefined)
  const [pricing, setPricing] = useState(
    selectedEvent ? selectedEvent.priceInfo : [],
  )
  const successToast = (message = undefined) => {
    return toast.success(message ? message : 'Pricing changed!', {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }

  const errorToast = (message = undefined) => {
    return toast.error(
      message ? message : 'Pricing not changed! Some error occurred.',
      {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      },
    )
  }

  useEffect(() => {
    setPricing(selectedEvent ? selectedEvent.priceInfo : [])
  }, [selectedEvent])

  const handlePricing = async (e) => {
    e.preventDefault()
    try {
      if (!editMode) {
        const updatedPricing = [
          ...pricing,
          {
            title: titleInputReference.current.value,
            physical: parseInt(priceInputReference.current.value),
          },
        ]
        const updateResponse = await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/v1/events/${selectedEvent._id}`,
          {
            priceInfo: updatedPricing,
          },
        )
        setPricing(updateResponse.data.payload.event.priceInfo)
        setSelectedEvent(updateResponse.data.payload.event)
        successToast('Pricing added!')
      } else {
        const updatedPricing = pricing
        updatedPricing.splice(editIndex, 1, {
          title: titleInputReference.current.value,
          physical: priceInputReference.current.value,
        })
        const updateResponse = await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/v1/events/${selectedEvent._id}`,
          {
            priceInfo: updatedPricing,
          },
        )
        setPricing(updateResponse.data.payload.event.priceInfo)
        setSelectedEvent(updateResponse.data.payload.event)
        successToast('Pricing modified!')
      }
      // alert('Pricing changed!')
    } catch (err) {
      // alert('Error!')
      editMode
        ? errorToast('Some error occurred while editing the pricing!')
        : errorToast('Some error occurred while adding the pricing!')
      console.log(err)
    }
  }

  const editPriceInfo = (index) => {
    setEditMode(true)
    setEditIndex(index)
  }

  const activateStripe = async (e) => {
    e.preventDefault();
    const stripeResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/payments/stripe/account`);
    // const updateBusinessAccount
    window.location.assign(stripeResponse.data.message.redirectLink.url)
  }

  const deletePriceInfo = async (index) => {
    try {
      const updatedPricing = pricing
      updatedPricing.splice(index, 1)
      const updateResponse = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/v1/events/${selectedEvent._id}`,
        {
          priceInfo: updatedPricing,
        },
      )
      setPricing(updateResponse.data.payload.event.priceInfo)
      setSelectedEvent(updateResponse.data.payload.event)
      // alert('Pricing changed!')
      successToast('Pricing deleted!')
    } catch (err) {
      // alert('Error!')
      successToast('Some error occurred while deleting the pricing!')
      console.log(err)
    }
  }

  if (!selectedEvent) {
    return <Navigate to="/org/dashboard" />
  } else {
    return (
      <div className="page">
        {pending && <h1>Wait a minute...</h1>}
        {businessAccount && (businessAccount.payload.businessAccount.stripe.stripeAccountId === '' ?
          <div>
            <button onClick={activateStripe}>You do not have Stripe activated! Click here to activate.</button>
            {/* <button onClick={redirectStripe}>Initiation completed! Click here to redirect...</button> */}
          </div> :
          businessAccount.payload.businessAccount.stripe.paymentsEnabled ?
            <>
              <div className="section-header">
                {selectedEvent.name}, {selectedEvent.location.city} (
                {selectedEvent.date})
              </div>
              <section className="pricing-info-container">
                <div className="pricing-info-header">Pricing</div>
                <main className="pricing-info-body">
                  <form
                    onSubmit={handlePricing}
                    className="grid grid-cols-16 gap-3 justify-center items-end"
                  >
                    <div className="col-span-4"></div>
                    <div className="col-span-4">
                      <label htmlFor="title" className="pricing-input-label">
                        Pricing title
                      </label>
                      <input
                        type="text"
                        id="title"
                        className="pricing-details col-span-4"
                        name="title"
                        ref={titleInputReference}
                        defaultValue={editIndex ? pricing[editIndex].title : ''}
                      />
                    </div>
                    <div className="col-span-2">
                      <label htmlFor="cost" className="pricing-input-label">
                        Cost
                      </label>
                      <input
                        type="number"
                        id="cost"
                        className="pricing-details col-span-2"
                        name="cost"
                        ref={priceInputReference}
                        defaultValue={
                          editIndex
                            ? pricing[editIndex].physical
                              ? pricing[editIndex].physical
                              : pricing[editIndex].digital
                                ? pricing[editIndex].digital
                                : ''
                            : ''
                        }
                      />
                    </div>
                    <button
                      className="col-span-1 pricing-action-submit"
                      type="submit"
                    >
                      {editMode ? 'Update' : 'Save'}
                    </button>
                    &nbsp;
                    <button
                      className="col-span-1 pricing-action-cancel"
                      onClick={(e) => {
                        e.preventDefault()
                        setEditIndex(undefined)
                        if (editMode) {
                          setEditMode(false)
                        }
                      }}
                    >
                      Cancel
                    </button>
                  </form>
                  {/* <div className="bg-slate-500 w-1/3 pricing-add-new-option">
                        <label className="create-event-input-label" htmlFor="event-name">
                            Event name
                        </label>
                        <input
                            onChange={handlePricingDetails}
                            type="text"
                            id="event-name"
                            name="name"
                            className="create-event-input"
                        />
                        <label className="create-event-input-label" htmlFor="event-name">
                            Event name
                        </label>
                        <input
                            onChange={handlePricingDetails}
                            type="text"
                            id="event-name"
                            name="name"
                            className="create-event-input"
                        />
                    </div> */}
                  {pricing.map((pricing, index) => {
                    return (
                      <div
                        className="pricing-info-box grid grid-cols-6"
                        tabIndex={1}
                        key={index}
                      >
                        <div className="col-span-3">{pricing.title}</div>
                        <div className="col-span-1 text-left">
                          $ {pricing.physical || pricing.digital}
                        </div>
                        <div className="col-span-1 pricing-modification-button">
                          <button
                            className="pricing-info-modify"
                            onClick={(e) => {
                              e.preventDefault()
                              editPriceInfo(index)
                            }}
                          >
                            Edit
                          </button>
                        </div>
                        <div className="col-span-1 pricing-modification-button text-right">
                          <button
                            className="pricing-info-modify"
                            onClick={(e) => {
                              e.preventDefault()
                              deletePriceInfo(index)
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    )
                  })}
                </main>
              </section>
              <ToastContainer />
            </> :
            <>
              {/* <a href={businessAccount.payload.businessAccount.stripe.stripeAccountLink}>Stripe account</a> activation is pending. */}
              Stripe account activation is pending...
            </>)
        }
        {error && <>Please create a business account first!</>}
      </div>
    )
  }
}

export default Pricing
