import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { Button } from '@mui/material'
import '../../assets/css/Gallery.css'
import Card from '../../components/Card'
import Grid from '@mui/material/Grid'
import { GetEventSpecificImages } from '../../services/aws.service'

const Gallery = () => {
  const navigate = useNavigate()
  const { name } = useParams()
  const [img, setImg] = useState([])

  useEffect(() => {
    ;(async () => {
      const data = await GetEventSpecificImages({ name })
      setImg(data.actualurl)
    })()
  }, [name])

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Button
              variant="contained"
              style={{ backgroundColor: '#002B5B', fontFamily: 'Noto Sans JP' }}
              onClick={() => navigate('/')}
            >
              Home
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      <main>
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="xl">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
              style={{ fontFamily: 'Noto Sans JP' }}
            >
              ALL IMAGES
            </Typography>

            <Grid container spacing={8}>
              {img
                ? img.map((item, index) => (
                    <>
                      <Grid
                        item
                        xl={4}
                        xs={12}
                        lg={2}
                        sm={6}
                        md={4}
                        key={index}
                      >
                        <Card url={item} />
                      </Grid>
                    </>
                  ))
                : null}
            </Grid>
          </Container>
        </Box>
      </main>
    </>
  )
}

export default Gallery
