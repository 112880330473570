import React, { useEffect, useState } from 'react'
import Footer from '../../components/_Footer'
import NavBar from '../../components/_Navbar'
import './style.css'
import { useParams, useNavigate } from 'react-router-dom'
import { GetEventDetailsFromPhoto } from '../../services/event.service'
import { saveAs } from 'file-saver'
import { toast, ToastContainer } from 'react-toastify'

function CustomProduct() {
  let { pid, eventID } = useParams()
  let navigate = useNavigate()
  const [data, setData] = useState([])
  // let tp = new Array(5).fill(0)
  // const [counter, setCounter] = useState(tp)

  useEffect(() => {
    ;(async () => {
      const response = await GetEventDetailsFromPhoto({ pid })
      setData(response)
    })()
  }, [pid])

  const downloadImage = () => {
    saveAs(data[2], 'image.jpg') // Put your image url here.
  }

  // const handleIncrement = useCallback((selected) => {
  //   setCounter((current) =>
  //     Object.assign([], current, {
  //       [selected]: current[selected] + 1,
  //     }),
  //   )
  // }, [])

  // const handleDecrement = useCallback((selected) => {
  //   setCounter((current) =>
  //     Object.assign([], current, {
  //       [selected]: current[selected] - 1,
  //     }),
  //   )
  // }, [])
  const notify = () =>
    toast.success('Added to Cart!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    })
  const notSameEvents = () =>
    toast.error('Different Events Not Allowed!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    })

  const AddtoCart = async () => {
    let existing = JSON.parse(localStorage.getItem('cart') || '[]')
    if (existing.length !== 0) {
      if (JSON.parse(existing[0]).eventID !== eventID) {
        notSameEvents()
        return
      }
    }

    existing.push(
      JSON.stringify({
        photoID: pid,
        type: 'digital',
        product: data[0].priceInfo[0].title,
        photoUrl: data[2],
        eventID,
      }),
    )
    localStorage.setItem('cart', JSON.stringify(existing))
    notify()

    // for (let i = 0; i < counter.length; i++) {
    //   console.log(counter[i])
    //   if (counter[i] !== 0) {
    //     localStorage.setItem(
    //       pid + '-' + i,
    //       JSON.stringify({
    //         photoID: pid,
    //         type: data[0].priceInfo[i].physical ? 'physical' : 'digital',
    //         title: data[0].priceInfo[i].title,
    //         photoUrl: data[0].photoUrl.original,
    //       }),
    //     )
    //   }
    // }
  }

  const checkOut = async () => {
    navigate(`/cart`)
  }

  return (
    <>
      <NavBar eventID={eventID} />
      <div
        className=" pl-20 pr-20 pt-24 pb-20"
        style={{ minHeight: '20vh', background: '#f0f0f0' }}
      >
        <ToastContainer />
        <div
          style={{ fontSize: '14px', fontWeight: 600 }}
          onClick={() => navigate(-1)}
        >
          <button className="back-to-gallery-btn">Back to Gallery</button>
        </div>
        {data.length && (
          <div
            className=" flex justify-around items-center flex-wrap mt-4 mb-14"
            style={{ background: '#f0f0f0' }}
          >
            <div className=" w-full md:w-3/5">
              <img
                style={{ height: '640px', objectFit: 'cover' }}
                src={data[1]}
                alt="product icon"
              />
            </div>
            <div className=" product_details">
              <div className="MAR-DEL-PLATA-MARATHON-ARGENTINA">
                {data[0]['name']}, {data[0]['location']['city']}
              </div>
              <div className="Photo-by-Raymond-Peters">Photo by: XYZ</div>
              <div className="custom-line"></div>
              {data[0]['priceInfo']?.map((item, index) => {
                return (
                  <div className=" flex items-center p-2" key={index}>
                    <div
                      style={{
                        fontSize: '14px',
                        marginRight: '25px',
                        marginTop: '10px',
                      }}
                    >
                      {item.digital ? 'Digital Photo' : null}
                    </div>
                    <div
                      className=""
                      style={{
                        marginTop: '10px',
                        fontSize: '14px',
                        fontWeight: 'bold',
                      }}
                    >
                      {item.digital ? item.digital : null}
                    </div>
                    <div
                      style={{
                        fontSize: '14px',
                        marginRight: '45px',
                        marginTop: '10px',
                      }}
                    >
                      {item.physical ? 'Physical Photo' : null}
                    </div>
                    <div
                      className=""
                      style={{
                        marginTop: '10px',
                        fontSize: '14px',
                        fontWeight: 'bold',
                      }}
                    >
                      {item.physical ? item.physical : null}
                    </div>
                    {/* <div
                      className=""
                      style={{
                        marginTop: '10px',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        marginLeft: '30px',
                      }}
                    >
                      <GroupedButtons
                        selected={index}
                        counter={counter}
                        handleIncrement={handleIncrement}
                        handleDecrement={handleDecrement}
                      />
                    </div> */}
                  </div>
                )
              })}
              <div className="custom-line"></div>

              {/* <div className="add-photo-print">Add Photo Print</div> */}
              {data[0]['monetisationEnabled'] ? (
                <button className="add-to-cart-btn" onClick={AddtoCart}>
                  Add to Cart
                </button>
              ) : (
                <button className="add-to-cart-btn" onClick={downloadImage}>
                  Download
                </button>
              )}
              {/* <button className="share-btn">Share</button> */}
              <button className="add-to-cart-btn" onClick={checkOut}>
                Checkout
              </button>
            </div>
          </div>
        )}
        {/* <div style={{ fontSize: "16px", fontWeight: 600, marginLeft: "20px" }}>
          {" "}
          Similar Photos
        </div> */}
        <Footer />
      </div>
    </>
  )
}

export default CustomProduct
