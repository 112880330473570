import React, { useEffect, useState, useRef } from 'react'
import RefreshIcon from '@mui/icons-material/Refresh'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import Switch from '@mui/material/Switch'
import imageCompression from 'browser-image-compression'
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import '../../assets/css/commonPageStyles.css'
import './styles.css'
import {
  GenerateUploadURL,
  PersistImage,
  GetCloudinarySignature,
} from '../../services/aws.service'
import Popup from 'reactjs-popup';
import {
  GetImagesFromEventID,
  FaceTagImagesForEvent,
  BIBTagImagesForEvent
} from '../../services/admin.service'
import { toast, ToastContainer } from 'react-toastify'
import { useDropzone } from 'react-dropzone'

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
}

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
}

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
}

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
}

const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
}
const ImageUpload = (props) => {
  const navigate = useNavigate();
  const event = props.selectedEvent
  useEffect(() => {
    if (!props.selectedEvent) {
      return navigate('/org/dashboard');
    }
  }, [])
  const [pictures, setPictures] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [opendialog, setOpendialog] = useState(false)
  const [compressedFiles, setCompressedFiles] = useState([])
  const [eventPhotos, setEventPhotos] = useState([])
  const [eventPhotosError, setEventPhotosError] = useState(false)
  const [eventPhotosLoading, setEventPhotosLoading] = useState(true)
  const toastId = useRef(null)

  useEffect(() => {
    ; (async () => {
      try {
        const photos = await GetImagesFromEventID({
          eventID: props.selectedEvent.eventID,
        })
        setEventPhotos(photos.photos)
      } catch (e) {
        setEventPhotosError(e)
      } finally {
        setEventPhotosLoading(false)
      }
    })()
  }, [refresh, props.selectedEvent])

  const handleClickOpen = () => {
    setOpendialog(true)
  }

  const handleClose = () => {
    setOpendialog(false)
    setPictures([])
    setCompressedFiles([])
  }

  const toggleCheckAllPhotos = (e) => {
    const allPhotos = document.querySelectorAll('.photo-checkbox')
    if (e.target.checked) {
      allPhotos.forEach((photo) => {
        photo.checked = true
      })
    } else {
      allPhotos.forEach((photo) => {
        photo.checked = false
      })
    }
  }

  const handleImageDelete = async (photo) => {
    if (window.confirm("Are you sure you want to delete this photo?")) {
      const { data: { payload: { photo: deletedPhoto } } } = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/v1/photos/${photo._id}`, {
        isDeleted: true
      });
      toastId.current = toast.success('Deleted the selected photo!', {
        position: 'top-center',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      })
      setRefresh(!refresh)
    }
    else {
      return;
    }
  }

  // const onDrop = async (picture) => {
  //   let compressedImages = []
  //   for (let i of picture) {
  //     setPictures((pehle) => [...pehle, i])
  //     let compressedFile = await imageCompression(i, options)
  //     compressedImages.push(compressedFile)
  //   }

  //   setCompressedFiles(compressedImages)
  // }

  function Previews(props) {
    const { getRootProps, getInputProps } = useDropzone({
      accept: {
        'image/jpeg': [],
        'image/png': [],
        'image/jpg': [],
      },
      maxSize: 26214400,
      onDrop: async (acceptedFiles) => {
        let compressedImages = []
        for (let i of acceptedFiles) {
          setPictures((pehle) => [...pehle, i])
          let compressedFile = await imageCompression(i, options)
          compressedImages.push(compressedFile)
        }

        setCompressedFiles(compressedImages)
        setPictures(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            }),
          ),
        )
      },
    })

    const thumbs = pictures.map((file) => (
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img
            src={file.preview}
            style={img}
            // Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(file.preview)
            }}
            alt=""
          />
        </div>
      </div>
    ))
    useEffect(() => {
      // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
      return () => pictures.forEach((file) => URL.revokeObjectURL(file.preview))
    }, [])

    return (
      <section className="container">
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
        </div>
        <aside style={thumbsContainer}>{thumbs}</aside>
      </section>
    )
  }

  const start = () =>
  (toastId.current = toast.loading('Uploading', {
    position: 'top-center',
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: 'light',
  }))

  const confirmUpload = async () => {
    start()
    let filedata = new FormData()
    let filenames = []

    for (var i = 0; i < pictures.length; i++) {
      filenames.push(pictures[i].name)
      filedata.append('images', compressedFiles[i])
    }
    filedata.append('size', pictures.length)

    const awsresponse = await GenerateUploadURL({
      filenames,
      quantity: pictures.length,
    })

    toast.update(toastId.current, {
      render: 'Fetching Cloudinary URLs...',
      type: 'info',
      isLoading: true,
    })
    const cloudinaryResp = await GetCloudinarySignature()

    let formData = new FormData()

    const watermarked = []

    toast.update(toastId.current, {
      render: 'Uploading the images...',
      type: 'info',
      isLoading: true,
      progress: 0
    })

    let progress = 0;

    for (let i = 0; i < pictures.length; i++) {
      let file = compressedFiles[i]
      formData.append('file', file)
      formData.append('api_key', cloudinaryResp.finresult[1])
      formData.append('timestamp', cloudinaryResp.finresult[0].timestamp)
      formData.append('signature', cloudinaryResp.finresult[0].signature)
      formData.append(
        'transformation',
        'c_thumb,l_iy99ijbhlsqqutzkbina,o_40,w_400',
      )
      formData.append('folder', 'test_upload')
      try {
        const response = await axios
          .post(cloudinaryResp.finresult[2], formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
        watermarked.push(response.data.url)
        await PersistImage({
          urls: [awsresponse.url[i].split('?')[0]],
          eventID: props.selectedEvent.eventID,
          imageNames: [filenames[i]],
          watermarked: [response.data.url],
          s3ImageIds: [awsresponse.s3ImageIds[i]]
        })
        progress = (i + 1) / pictures.length;
        // JSON.stringify(JSON.parse(data), null, 4)
        toast.update(toastId.current, { progress })
      }
      catch (err) {
        // console.log(err);
      }
      const myHeaders = new Headers({ 'Content-Type': 'image/*' })

      if (awsresponse.url.length > 0) {
        for (let i = 0; i < pictures.length; i++) {
          fetch(awsresponse.url[i], {
            method: 'PUT',
            headers: myHeaders,
            body: pictures[i],
          })
        }
      } else {
        // console.log(awsresponse)
      }
    }
    if (parseInt(progress) === 1) {
      toast.update(toastId.current, {
        render: 'Completed!',
        type: 'success',
        progress: 1,
        isLoading: false,
      })
      setRefresh(!refresh);
    }
    else {
      toast.update(toastId.current, {
        render: 'Failed to upload images ' + progress.toString(),
        type: 'error',
        isLoading: false
      })
    }

    handleClose()
  }

  const tagImagesPromise = async (eventID) => {
    try {
      const bibTaggingResponse = await BIBTagImagesForEvent({ eventID });
      console.log("BIB Tagging done: ", bibTaggingResponse);
      const faceTaggingResponse = await FaceTagImagesForEvent({ eventID });
      console.log("Face tagging done: ", faceTaggingResponse);
      return true;
    }
    catch (err) {
      return err;
    }
  }

  const tagImages = async () => {
    const response = await toast.promise(
      tagImagesPromise(event._id),
      {
        pending: 'Processing...',
        success: 'Tagging successful!',
        error: 'Some Error!',
      },
    )
    // console.log(response)
  }

  return (
    <div className="page">
      <ToastContainer />
      <div className="section-header">
        {event && event.name ? event.name : 'Unknown event name'},&nbsp;
        {event && event.location && event.location.city
          ? event.location.city
          : 'Unknown event location'}
        &nbsp; (
        {event && event.date
          ? `${new Date(event.date).getFullYear()}`
          : 'Unknown date'}
        )
      </div>
      <div className="table">
        <section
          className="table-header flex items-center"
          style={{ width: '100%' }}
        >
          <div className="table-header-section flex">Photo Upload</div>
          <div className="table-header-section flex">
            {eventPhotos
              ? eventPhotos.length
              : 'Unknown'}{' '}
            Photos uploaded,{' '}
            {eventPhotos
              ? eventPhotos.filter((photo) => photo.status.tagging).length
              : 'unknown'}{' '}
            Photos tagged
          </div>
          <div className="table-header-section grid grid-cols-12 space-around">
            <button
              className="table-header-section-button col-span-4 col-start-2"
              onClick={tagImages}
            >
              System Tag
            </button>
            <button
              className="table-header-section-button col-span-4 col-start-7"
              onClick={handleClickOpen}
            >
              Upload
            </button>
            <button onClick={() => setRefresh(!refresh)}>
              <RefreshIcon />
            </button>

            <Dialog
              maxWidth="lg"
              fullWidth={true}
              // fullScreen={fullScreen}
              open={opendialog}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle
                id="responsive-dialog-title"
                sx={{ fontFamily: 'arial' }}
              >
                {`Upload The Images for ${event ? event.name : "unknown event"}`}
              </DialogTitle>
              <DialogContent>
                <Previews />
              </DialogContent>
              <DialogActions>
                <button
                  className="def-button"
                  autoFocus
                  // onClick={handleClose}
                  onClick={
                    pictures.length > 0
                      ? () => {
                        confirmUpload()
                      }
                      : null
                  }
                  sx={{ fontFamily: 'arial' }}
                >
                  Upload
                </button>
                <button
                  className="def-button"
                  onClick={handleClose}
                  autoFocus
                  sx={{ fontFamily: 'arial' }}
                >
                  Cancel
                </button>
              </DialogActions>
            </Dialog>
          </div>
        </section>
        <table>
          <thead>
            <tr>
              <th>
                <input type="checkbox" onChange={toggleCheckAllPhotos} />
              </th>
              <th>Name</th>
              <th>Tag Status</th>
              <th>Tags</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {eventPhotos &&
              eventPhotos.map((photo, index) => {
                if (photo.isDeleted !== true) {
                  return (
                    <tr
                      id={`photo-${index}`}
                      style={{
                        cursor: 'pointer',
                      }}
                      key={`photo-${index}`}
                      className='photo-row'
                    >
                      <td>
                        <input className="photo-checkbox" type="checkbox" />
                      </td>
                      <td>{photo.imageName ? photo.imageName.slice(0, 20) : photo.photoUrl.original.slice(0, 20)}...</td>
                      <td>
                        <span
                          className={`photo-tag-status photo-tag-status-${photo.status.tagging ? 'completed' : 'pending'
                            }`}
                        >
                          {photo.status.tagging ? 'completed' : 'pending'}
                        </span>
                      </td>

                      {photo.tagInfo.bib && photo.tagInfo.bib.length > 0 ? (
                        <td>
                          <span className="photo-tags">
                            {`BIB: ${photo.tagInfo.bib.reduce(
                              (accumulator, element, index) => {
                                if (index < 3) {
                                  return accumulator + ', ' + element
                                } else if (index === 3) {
                                  return accumulator + '...'
                                } else {
                                  return accumulator
                                }
                              },
                            )}`}
                          </span>
                        </td>
                      ) : (
                        <td>
                          <span className="photo-tags">
                            {`BIB: Not calculated yet!`}
                          </span>
                        </td>
                      )}
                      <td>
                        <button
                          onClick={(e) => {
                            e.preventDefault()
                            handleImageDelete(photo)
                          }}
                        >
                          <DeleteOutlineOutlinedIcon fontSize="small" />
                        </button>
                      </td>
                    </tr>
                  )
                }
              })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ImageUpload
