import React from 'react'
import { NavLink, Navigate, useNavigate } from 'react-router-dom'
import dummyEventObject from '../../dummyEventObject'
import PersonIcon from '@mui/icons-material/Person';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import GroupsIcon from '@mui/icons-material/Groups';
import LogoutIcon from '@mui/icons-material/Logout';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { signOut } from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import './styles.css'
// const Navbar = ({ selectedEvent, setSelectedEvent }) => {
//   const navigate = useNavigate();
//   return (
//     <div className="navbar">
//       <header>
//         <Link to="/org/dashboard">
//           <img
//             src="/logo/logo_800px@3x.png"
//             alt="Tamarind.ai logo"
//             className="logo"
//           />
//           Tamarind
//         </Link>
//       </header>
//       <button className="create-event-button" onClick={(e) => {
//         e.preventDefault();
//         navigate('/org/dashboard/events/create');
//       }}>
//         Create Event
//       </button>
//       <ul className="links-container">
//         <div className="events-section">
//           <div
//             className="link link-section-header"
//             onClick={(e) => {
//               setSelectedEvent(dummyEventObject)
//             }}
//           >
//             <Link to="/org/events">Events</Link>
//           </div>
//           {selectedEvent && (
//             <>
//               <div className="link">
//                 <Link to="/org/dashboard/events/details">Event details</Link>
//               </div>
//               <div className="link">
//                 <Link to="/org/dashboard/events/participants">
//                   Photo upload
//                 </Link>
//               </div>
//               <div className="link">
//                 <Link to="/org/dashboard/events/participants">
//                   (Photo Upload) Participants
//                 </Link>
//               </div>
//               <div className="link">
//                 <Link to="/org/dashboard/events/pricing">Pricing</Link>
//               </div>
//               <div className="link">
//                 <Link to="/org/dashboard/events/analytics">Analytics</Link>
//               </div>
//             </>
//           )}
//         </div>
//         <div className="account-section">
//           <div id="account-section-header" className="link link-section-header">
//             <Link to="/org/account">Account</Link>
//           </div>
//           <div className="link">
//             <Link to="/org/profile">Profile</Link>
//           </div>
//           <div className="link">
//             <Link to="/org/plans">Plans &#38; Pricing</Link>
//           </div>
//           <div className="link">
//             <Link to="/org/payment-history">Payment History</Link>
//           </div>
//         </div>
//       </ul>
//       <button className="upgrade-account-button">Upgrade account</button>
//       <ul className="support-links-container">
//         <div className="support-link">
//           <Link to="/org/help-centre">Help Centre</Link>
//         </div>
//         <div className="support-link">
//           <Link to="/org/community">Community</Link>
//         </div>
//         <div className="support-link">
//           <Link to="/org/logout">Log out</Link>
//         </div>
//       </ul>
//     </div>
//   )
// }

const Navbar = ({ selectedEvent, setSelectedEvent }) => {
	const navigate = useNavigate();
	return (
		<div className="navbar">
			{/* <header>
        <Link to="/org/dashboard">
          <img
            src="/logo/logo_800px@3x.png"
            alt="Tamarind.ai logo"
            className="logo"
          />
          Tamarind
        </Link>
      </header>
      <button className="create-event-button" onClick={(e) => {
        e.preventDefault();
        navigate('/org/dashboard/events/create');
      }}>
        Create Event
      </button> */}
			<header className="navbar-header grid place-content-center">
				<NavLink to="/org/dashboard">
					<img
						src="/logo/logo_800px@3x.png"
						alt="Tamarind.ai logo"
						className="logo"
					/>
					Tamarind
				</NavLink>
			</header>
			<div className="outer-navbar-container">
				<div className="inner-navbar-container grid justify-center">
					<button className="create-event-button" onClick={(e) => {
						e.preventDefault();
						navigate('/org/dashboard/events/create');
					}}>
						Create Event
					</button>
					<div
						className="navbar-link link-section-header"
						onClick={(e) => {
							setSelectedEvent(dummyEventObject)
						}}
					>
						<NavLink to="/org/events" className={({ isActive }) => {
							return isActive ? `navbar-link-active` : ``
						}}>
							Events &nbsp;
							{selectedEvent && <DirectionsRunIcon fontSize="small" />}
						</NavLink>
					</div>
					{selectedEvent && (
						<>
							<div className="navbar-link">
								<NavLink to="/org/dashboard/events/details" className={({ isActive }) => {
									return isActive ? `navbar-link-active` : ``
								}}>Event details</NavLink>
							</div>
							<div className="navbar-link">
								<NavLink to="/org/dashboard/events/upload" className={({ isActive }) => {
									return isActive ? `navbar-link-active` : ``
								}}>
									Photo upload
								</NavLink>
							</div>
							<div className="navbar-link">
								<NavLink to="/org/dashboard/events/participants" className={({ isActive }) => {
									return isActive ? `navbar-link-active` : ``
								}}>
									Participants
								</NavLink>
							</div>
							<div className="navbar-link">
								<NavLink to="/org/dashboard/events/pricing" className={({ isActive }) => {
									return isActive ? `navbar-link-active` : ``
								}}>Pricing</NavLink>
							</div>
							<div className="navbar-link">
								<NavLink to="/org/dashboard/events/analytics" className={({ isActive }) => {
									return isActive ? `navbar-link-active` : ``
								}}>Analytics</NavLink>
							</div>
						</>
					)}
					<div className="navbar-link link-section-header">
						<NavLink to="/org/account" className={({ isActive }) => {
							return isActive ? `navbar-link-active` : ``
						}}>
							Account &nbsp;<PersonIcon fontSize="small" />
						</NavLink>
					</div>
					<div className="navbar-link" >
						<NavLink to="/org/profile" className={({ isActive }) => {
							return isActive ? `navbar-link-active` : ``
						}}>Profile</NavLink>
					</div>
					<div className="navbar-link">
						<NavLink to="/org/plans" className={({ isActive }) => {
							return isActive ? `navbar-link-active` : ``
						}}>Plans &#38; Pricing</NavLink>
					</div>
					<div className="navbar-link">
						<NavLink to="/org/payment-history" className={({ isActive }) => {
							return isActive ? `navbar-link-active` : ``
						}}>Payment History</NavLink>
					</div>
					<button className="upgrade-account-button">Upgrade account</button>
					<div className="navbar-link">
						<NavLink to="/org/help-centre" className={({ isActive }) => {
							return isActive ? `navbar-link-active` : ``
						}}>
							Help Centre &nbsp;<HeadsetMicIcon fontSize="small" />
						</NavLink>
					</div>
					<div className="navbar-link">
						<NavLink to="/org/community" className={({ isActive }) => {
							return isActive ? `navbar-link-active` : ``
						}}>
							Community &nbsp;<GroupsIcon fontSize="small" />
						</NavLink>
					</div>
					<div className="navbar-link">
						<button onClick={async (e) => {
							await signOut();
							navigate("/auth");
						}}>
							Log out &nbsp;<LogoutIcon fontSize="small" />
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Navbar